define("ember-drag-drop/components/sortable-objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    dragCoordinator: Ember.inject.service(),
    overrideClass: 'sortable-objects',
    classNameBindings: ['overrideClass'],
    enableSort: true,
    useSwap: true,
    inPlace: false,
    sortingScope: 'drag-objects',
    sortableObjectList: Ember.A(),
    init() {
      this._super(...arguments);
      if (this.get('enableSort')) {
        this.get('dragCoordinator').pushSortComponent(this);
      }
    },
    willDestroyElement() {
      if (this.get('enableSort')) {
        this.get('dragCoordinator').removeSortComponent(this);
      }
    },
    dragStart(event) {
      event.stopPropagation();
      if (!this.get('enableSort')) {
        return false;
      }
      this.set('dragCoordinator.sortComponentController', this);
    },
    dragEnter(event) {
      //needed so drop event will fire
      event.stopPropagation();
      return false;
    },
    dragOver(event) {
      //needed so drop event will fire
      event.stopPropagation();
      return false;
    },
    drop(event) {
      event.stopPropagation();
      event.preventDefault();
      this.set('dragCoordinator.sortComponentController', undefined);
      if (this.get('enableSort') && this.get('sortEndAction')) {
        this.get('sortEndAction')(event);
      }
    }
  });
  _exports.default = _default;
});